

/* eslint-disable react/no-danger */
import React, { FC, useState, useEffect } from 'react';
import { ContentPageLayout } from '@components/Layout';
import { IMarkdownData } from '../types';
import { TextSection } from '@components/TextSection';
import { Button, Input, Select, Spinner } from '@components/Primitives';
import styled from 'styled-components';
import { PushNotificationType } from '../services/api';
import ToastStore from '../stores/ToastStore';
import PushService from '../services/PushService';
import { useStores } from '../stores/index';
import { ICurrentUserStore } from '../types/CurrentUser';
import { observer } from 'mobx-react';
import { IAgreementStore } from '../types/Agreement';


interface IPushTestPage {
  data: IMarkdownData;
}

const StyledInput = styled(Input)`

`;

const StyledButton = styled(Button)`
  margin-left: 1rem;
`;

const StyledForm = styled.form`
  display: flex;
  justify-content: center;
`;

const PushTestPage: FC<IPushTestPage> = observer(({
}) => {
  const [type, setType] = useState<PushNotificationType>('events');
  const [loading, setLoading] = useState(false);


  const {
    currentUserStore: { currentUser },
    agreementStore: { getAgreementParties, selectedAgreementId, agreementParties }
  }: {
    currentUserStore: ICurrentUserStore;
    agreementStore: IAgreementStore;
  } = useStores();

  useEffect(() => {
    if (!agreementParties && selectedAgreementId) {
      getAgreementParties(selectedAgreementId);
    }
  }, [agreementParties, selectedAgreementId]);

  const onSubmit = async (e: any) => {
    e.preventDefault();
    setLoading(true);

    try {
      await PushService.testPush('55', type)
      setLoading(false);
      ToastStore.showSuccess('Onnistu');
    } catch (error) {
      setLoading(false);
      ToastStore.showError('Ei onnistunu');
    }
  };

  const onTypeChange = (e: any) => {
    setType(e.target.value);
  };

  return (
    <ContentPageLayout>
      <TextSection title="Push-viesti testi" text="Valitse notifikaatiotyyppi ja lähetä" />
      <StyledForm onSubmit={onSubmit}>
        {loading && <Spinner color="mainBlue" />}
        <Select
          id="type"
          skipTranslation
          value={type}
          onChange={onTypeChange}
          options={[
            { id: 'events', label: 'Tapahtuma' },
            { id: 'messages', label: 'VIesti' },
            { id: 'faultreports', label: 'Vikailmoitus' },
            { id: 'exceptions', label: 'Poikkeusilmoitus' },
            { id: 'notifications', label: 'Notifikaatio?' },
          ]}>
          <option value="events">Tapahtuma</option>
          <option value="messages">Viesti</option>
          <option value="faultreports">Vikailmoitus</option>
          <option value="exceptions">Poikkeusilmoitus</option>
          <option value="notifications">Notifikaatio(?)</option>
        </Select>

        <StyledButton type="submit" id="submit">Lähetä</StyledButton>
      </StyledForm>
    </ContentPageLayout >
  );
});

export default PushTestPage;
